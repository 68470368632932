import { Modal } from "antd";
import PropTypes from "prop-types";
import { Component, default as React } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Entregas from "../../components/Entregas/Entregas";
import PendientesMapper from "../../Services/Mappers/PendientesMapper";
import EntregasEpisService from "../../Services/EntregasEpisService";
import EntregasEpisActions from "../../store/actions/entregasEpisActions";
import CommonActions from "../../store/actions/commonActions";
import { LoadingPage } from "./../../components/shared";
import ModalErrorDialog from "./../../components/shared/ModalErrorDialog/ModalErrorDialog";

class EntregasContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //parametros alert de resultado de la operacion
      alert: {
        visible: false,
        message: "",
        description: "",
        type: "",
      },
    };
  }

  async componentDidMount() {
    this.props.actions.commonActions.displayLoading(true);
    const response = await EntregasEpisService.obtenerEpisPendientesByIdEmpleado();
    var data = [];
    if (response.isSuccess) {
      data = PendientesMapper.jsonToEpisPendientesEmpleadoObjectList(
        response.data
      );
    } else if (response.errorMessage) {
      Modal.error({
        title: "Error",
        content: response.errorMessage.msg,
      });
    }
    this.props.actions.pendientesActions.updateDatosEpisPendientesEmpleados(
      data
    );
    this.props.actions.commonActions.displayLoading(false);
  }

  cambiarEstadoLote = async (notificacion, nuevoEstado) => {
    const { t } = this.props;
    const response = await EntregasEpisService.actualizarEstadoNotificacionById(
      notificacion,
      nuevoEstado
    );

    if (response.isSuccess && nuevoEstado.statusCode !== "02") {
      this.props.actions.commonActions.displayLoading(true);
      const response2 = await EntregasEpisService.obtenerEpisPendientesByIdEmpleado();
      if (response2.isSuccess) {
        const data = PendientesMapper.jsonToEpisPendientesEmpleadoObjectList(
          response2.data
        );
        this.props.actions.pendientesActions.updateDatosEpisPendientesEmpleados(
          data
        );
        this.setState({
          alert: {
            type: "success",
            visible: true,
            message: t("entregasEpis.messages.messages6"),
            description: `${t("entregasEpis.messages.messages1")} ${notificacion}`,
          },
        });
        this.props.actions.commonActions.displayLoading(false);
        this.delayCloseAlertComponent();
      }
    } else if (!response.isSuccess) {
      this.setState({
        alert: {
          type: "error",
          visible: true,
          message: t("entregasEpis.messages.messages7"),
          description: `${t("entregasEpis.messages.messages3")} ${notificacion}`,
        },
      });
      this.delayCloseAlertComponent();
    }
    return response;
  };

  delayCloseAlertComponent() {
    setTimeout(
      function () {
        this.setState({
          alert: {
            visible: false,
            message: "",
            description: "",
            type: "",
          },
        });
      }.bind(this),
      5000
    );
  };

  handleClose = () => {
    this.setState({ alert: { visible: false } });
  };

  render() {
    if (!this.props.isLoading) {
      return (
        <>
          {this.state.alert.visible ? (
            <ModalErrorDialog
              closable
              message={this.state.alert.message}
              description={this.state.alert.description}
              type={this.state.alert.type}
              showIcon
              afterClose={this.handleClose}
            />
          ) : null}
          <Entregas
            entregaSelecionada={this.props.match?.params?.id}
            listadoEpisPendientesEmpleados={
              this.props.episPendientes.episPendientesEmpleados
            }
            cambiarEstadoLote={this.cambiarEstadoLote.bind()}
          />
        </>
      );
    } else {
      return <LoadingPage />;
    }
  }
}

EntregasContainer.propTypes = {
  actions: PropTypes.shape({
    pendientesActions: PropTypes.shape({
      updateDatosEpisPendientesEmpleados: PropTypes.func,
    }),
    commonActions: PropTypes.shape({
      displayLoading: PropTypes.func,
    }),
  }),
  episPendientes: PropTypes.shape({
    episPendientesEmpleados: PropTypes.any,
  }),
  isLoading: PropTypes.any,
  location: PropTypes.shape({
    state: PropTypes.shape({
      entregaSelecionada: PropTypes.any,
    }),
  }),
  t: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    episPendientes: state.episPendientesEmpleadosState,
    isLoading: state.commonState.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      pendientesActions: bindActionCreators(
        EntregasEpisActions,
        dispatch
      ),
      commonActions: bindActionCreators(CommonActions, dispatch),
    },
  };
};

const redux = connect(
  mapStateToProps,
  mapDispatchToProps
)(EntregasContainer);

export default withTranslation()(redux);
