import { Alert, Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class Alertas extends Component {
  onClose = () => {
    // This is intentional.
  };

  renderAlertas() {
    const listaAlertas = [];
    const numeroAlertas = 4;

    for (let i = 1; i < numeroAlertas; i++) {
      listaAlertas.push(
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Alert
            message="Titulo de la alerta"
            description="Alerta Description Error Description "
            type="info"
            closable
            onClose={this.onClose}
            showIcon
          />
        </Col>
      );
    }
    return listaAlertas;
  }

  render() {
    const gutter = 15;
    return (
      <div>
        <Row gutter={[gutter, gutter]} type="flex" justify="space-between">
          {this.renderAlertas()}
        </Row>
      </div>
    );
  }
}

Alertas.propTypes = {
  isLoading: PropTypes.any,
  listadoTareasPendientes: PropTypes.any,
  refresh: PropTypes.any,
  t: PropTypes.func,
};

export default withTranslation()(Alertas);
