import _ from "lodash";
import { utils } from "../../utils/helpers";

class HistoricoMapper {

  async jsonToHistoricoEmpleadoObject(data) {
    let listadoEmpleadosProcesado = null;
    if (!utils.isEmptyObject(data)) {
      listadoEmpleadosProcesado = {
        id: data.id,
        entrega: data.status,
        fechaEntrega: data.deliveryDate,
        desTipoEpi: data.ipeType.description,
        modalidadEpi: data.ipeType.modality?.description,
        fechaDevolucion: data.returnDate,
        codBarrasEpi: data.ipeBarcode,
        codEtiquetaEpi: data.ipeTagCode,
        fechaCertificacionNoDevuelto: data.notReturnDate,
        motivo: data.depositReason?.description,
        unidadDestino: data.operativeUnit?.description,
      };
    }
    return listadoEmpleadosProcesado;
  }

  async jsonToHistoricoEmpleadoObjectList(dataList) {
    let objectList = [];
    if (!_.isEmpty(dataList) && dataList.length > 0) {
      objectList = await Promise.all(
        dataList.map(registro => this.jsonToHistoricoEmpleadoObject(registro))
      );
    }
    return objectList;
  }
}
export default new HistoricoMapper();
