import PropTypes from "prop-types";
import React, { Component } from "react";
import AppContext from "./appContext";
import { storeArq } from "correos-arq-utils";

class AppContextProvider extends Component {
  state = {
    storeArq: storeArq,
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          tablas: this.state,
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppContextProvider.propTypes = {
  children: PropTypes.any,
};

export default AppContextProvider;
