import React, { Component } from "react";
import { Card } from "antd";
import { withTranslation } from "react-i18next";

const { Meta } = Card;

class TarjetaCard extends Component {
  render() {
    return (
      <a href={this.props.href} target="_blank" className="Link" rel="noopener noreferrer">
        <Card bordered={false} cover={this.props.cover}>
          <Meta
            title="Lorem ipsum dolor sit"
            description="This is the description"
          />
        </Card>
      </a>
    );
  }
}

export default withTranslation()(TarjetaCard);
