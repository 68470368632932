import React from "react";
import _ from "lodash";
import { utils } from "../../../../utils/helpers";
import DateRangeFilter from "./../filtros/DateRangeFilter";
import InputFilter from "./../filtros/InputFilter";

export function getColumns(t, dataSource, filteredInfo, sortedInfo) {

  let filtrosEntrega = [];
  if (!_.isEmpty(dataSource)) {
    // eslint-disable-next-line
    dataSource.map((item) => {
      let value = {
        text: item.entrega.description,
        value: item.entrega.description,
      };
      if (value.text) {
        if (filtrosEntrega.indexOf(item) === -1) {
          filtrosEntrega.push(value);
        }
      }
    });
    filtrosEntrega = _.uniqWith(filtrosEntrega, _.isEqual);
  }

  let filtrosEpi = [];
  if (!_.isEmpty(dataSource)) {
    // eslint-disable-next-line
    dataSource.map((item) => {
      let value = {
        text: item.desTipoEpi,
        value: item.desTipoEpi,
      };
      if (value.text) {
        if (filtrosEpi.indexOf(item) === -1) {
          filtrosEpi.push(value);
        }
      }
    });
    filtrosEpi = _.uniqWith(filtrosEpi, _.isEqual);
  }

  let filtroModalidad = [];
  if (!_.isEmpty(dataSource)) {
    // eslint-disable-next-line
    dataSource.map((item) => {
      let value = {
        text: item.modalidadEpi,
        value: item.modalidadEpi,
      };

      if (value.text) {
        if (filtroModalidad.indexOf(item) === -1) {
          filtroModalidad.push(value);
        }
      }
    });
    filtroModalidad = _.uniqWith(filtroModalidad, _.isEqual);
  }

  let filtrosUnidadDestino = [];
  if (!_.isEmpty(dataSource)) {
    // eslint-disable-next-line
    dataSource.map((item) => {
      let value = {
        text: item.unidadDestino,
        value: item.unidadDestino,
      };
      if (value.text) {
        if (filtrosUnidadDestino.indexOf(item) === -1) {
          filtrosUnidadDestino.push(value);
        }
      }
    });
    filtrosUnidadDestino = _.uniqWith(filtrosUnidadDestino, _.isEqual);
  }

  let filtrosMotivo = [];
  if (!_.isEmpty(dataSource)) {
    // eslint-disable-next-line
    dataSource.map((item) => {
      let value = {
        text: item.motivo,
        value: item.motivo,
      };
      if (value.text) {
        if (filtrosMotivo.indexOf(item) === -1) {
          filtrosMotivo.push(value);
        }
      }
    });
    filtrosMotivo = _.uniqWith(filtrosMotivo, _.isEqual);
  }

  const columns = [
    {
      align: "center",
      title: t("historicoEpis.labels.fehaEntrega"),
      dataIndex: "fechaEntrega",
      key: "fechaEntrega",
      filteredValue: filteredInfo.fechaEntrega || null,
      sorter: (a, b) => a.fechaEntrega?.localeCompare(b.fechaEntrega),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'fechaEntrega' && sortedInfo.order,
      width: 40,
      filterDropdown: (a) => <DateRangeFilter {...a} />,
      onFilter: (value, item) => {
        const [from, to] = value;
        if (!from || !to) {
          return true;
        }
        return (
          from.startOf('day').isSameOrBefore(item.fechaEntrega) &&
          to.startOf('day').isSameOrAfter(item.fechaEntrega)
        );
      },
      render: (dataIndex) => utils.stringToDate(dataIndex),
    },
    {
      align: "center",
      title: t("historicoEpis.labels.entrega"),
      dataIndex: "entrega",
      key: "entrega",
      filteredValue: filteredInfo.entrega || null,
      sorter: (a, b) =>
        a.entrega.description?.localeCompare(b.entrega.description),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'entrega' && sortedInfo.order,
      filterMultiple: true,
      onFilter: (value, record) =>
        record.entrega?.description?.indexOf(value) === 0,
      render: (dataIndex) =>
        !_.isEmpty(dataIndex) ? utils.obtenerTagEstado(dataIndex, t) : null,
      width: 45,
      filters: filtrosEntrega,
    },
    {
      title: t("historicoEpis.labels.epi"),
      dataIndex: "desTipoEpi",
      key: "desTipoEpi",
      filteredValue: filteredInfo.desTipoEpi || null,
      sorter: (a, b) => a.desTipoEpi?.localeCompare(b.desTipoEpi),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'desTipoEpi' && sortedInfo.order,
      filterMultiple: true,
      onFilter: (value, record) => record.desTipoEpi?.indexOf(value) === 0,
      width: 85,
      filters: filtrosEpi,
    },
    {
      title: t("historicoEpis.labels.modalidadEpi"),
      dataIndex: "modalidadEpi",
      key: "modalidadEpi",
      filteredValue: filteredInfo.modalidadEpi || null,
      sorter: (a, b) => a.modalidadEpi?.localeCompare(b.modalidadEpi),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'modalidadEpi' && sortedInfo.order,
      width: 50,
      filterMultiple: true,
      filters: filtroModalidad,
      onFilter: (value, record) => record.modalidadEpi?.indexOf(value) === 0,
    },
    {
      align: "center",
      title: t("historicoEpis.labels.fechaDevolucion"),
      dataIndex: "fechaDevolucion",
      key: "fechaDevolucion",
      filteredValue: filteredInfo.fechaDevolucion || null,
      sorter: (a, b) => a.fechaDevolucion?.localeCompare(b.fechaDevolucion),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'fechaDevolucion' && sortedInfo.order,
      width: 55,
      filterDropdown: (a) => <DateRangeFilter {...a} />,
      onFilter: (value, item) => {
        const [from, to] = value;
        if (!from || !to) {
          return true;
        }
        return (
          from.startOf('day').isSameOrBefore(item.fechaDevolucion) &&
          to.startOf('day').isSameOrAfter(item.fechaDevolucion)
        );
      },
      render: (dataIndex) => utils.stringToDate(dataIndex),
    },
    {
      align: "center",
      title: t("historicoEpis.labels.cerNoDevuelto"),
      dataIndex: "fechaCertificacionNoDevuelto",
      key: "fechaCertificacionNoDevuelto",
      filteredValue: filteredInfo.fechaCertificacionNoDevuelto || null,
      sorter: (a, b) =>
        a.fechaCertificacionNoDevuelto?.localeCompare(
          b.fechaCertificacionNoDevuelto
        ),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'fechaCertificacionNoDevuelto' && sortedInfo.order,
      width: 55,
      filterDropdown: (a) => <DateRangeFilter {...a} />,
      onFilter: (value, item) => {
        const [from, to] = value;
        if (!from || !to) {
          return true;
        }
        return (
          from.startOf('day').isSameOrBefore(item.fechaCertificacionNoDevuelto) &&
          to.startOf('day').isSameOrAfter(item.fechaCertificacionNoDevuelto)
        );
      },
      render: (dataIndex) => utils.stringToDate(dataIndex),
    },
    {
      title: t("historicoEpis.labels.motivoDeposito"),
      dataIndex: "motivo",
      key: "motivo",
      filteredValue: filteredInfo.motivo || null,
      sorter: (a, b) => a.motivo?.localeCompare(b.motivo),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'motivo' && sortedInfo.order,
      width: 50,
      filterMultiple: true,
      filters: filtrosMotivo,
      onFilter: (value, record) => record.motivo?.indexOf(value) === 0,
    },
    {
      title: t("historicoEpis.labels.unidadDestino"),
      dataIndex: "unidadDestino",
      key: "unidadDestino",
      filteredValue: filteredInfo.unidadDestino || null,
      sorter: (a, b) => a.unidadDestino?.localeCompare(b.unidadDestino),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'unidadDestino' && sortedInfo.order,
      width: 55,
      filterMultiple: true,
      filters: filtrosUnidadDestino,
      onFilter: (value, record) => record.unidadDestino?.indexOf(value) === 0,
    },
    {
      title: t("historicoEpis.labels.codigoEtiqueta"),
      dataIndex: "codEtiquetaEpi",
      key: "codEtiquetaEpi",
      filteredValue: filteredInfo.codEtiquetaEpi || [],
      sorter: (a, b) => a.codEtiquetaEpi?.localeCompare(b.codEtiquetaEpi),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'codEtiquetaEpi' && sortedInfo.order,
      width: 50,
      ...InputFilter.getColumnSearchProps("codEtiquetaEpi", t("historicoEpis.labels.codigoEtiqueta"))
    },
    {
      title: t("historicoEpis.labels.codigoBarras"),
      dataIndex: "codBarrasEpi",
      key: "codBarrasEpi",
      filteredValue: filteredInfo.codBarrasEpi || [],
      sorter: (a, b) => a.codBarrasEpi?.localeCompare(b.codBarrasEpi),
      sortDirections: ["descend", "ascend"],
      sortOrder: sortedInfo.columnKey === 'codBarrasEpi' && sortedInfo.order,
      width: 70,
      ...InputFilter.getColumnSearchProps("codBarrasEpi", t("historicoEpis.labels.codigoBarras"))
    },
  ];
  return columns;
}

export default getColumns;
