import React from "react";
import _ from "lodash";
import { utils } from "../../../../utils/helpers";
import {
  Button,
} from "antd";

export function getColumns(t, handleClick) {

  const columns = [
    {
      align: "center",
      key: "fecha",
      title: "Fecha",
      dataIndex: "fecha",
      width: "20%",
      render: (dataIndex) => utils.stringToDate(dataIndex),
    },
    {
      key: "descripcion",
      title: "Descripción",
      dataIndex: "descripcion",
      width: "60%",
      render: (dataIndex, row) => (
        <Button type="link"
          id="entregas-link"
          onClick={() => handleClick(row, t)}
        >
          {dataIndex}
        </Button>
      ),
    },
    {
      align: "center",
      key: "estado",
      title: "Estado",
      dataIndex: "estado",
      width: "20%",
      render: (dataIndex) =>
        !_.isEmpty(dataIndex) ? utils.obtenerTagEstado(dataIndex, t) : null,
    }
  ];
  return columns;
}

export default getColumns;
