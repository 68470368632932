import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal } from "antd";
import { withTranslation } from "react-i18next";
import "./SimpleModal.scss";

class SimpleModal extends Component {
  render() {
    const props = this.props;
    const { t } = this.props;
    const buttonOk = (
      <Button type="default" key="cancelar" onClick={this.props.handleCancel}>
        {t("componentsGeneral.button.cancelar")}
      </Button>
    );
    const buttonCancelar = (
      <Button type="primary" key="aceptar" onClick={this.props.handleOk}>
        {t("componentsGeneral.button.aceptar")}
      </Button>
    );
    const renderFooter = [buttonOk, buttonCancelar];

    return (
      <Modal
        onCancel={this.props.handleCancel}
        title={t("componentsGeneral.title1")}
        visible={this.props.visible}
        centered
        closable={true}
        maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.68)" }}
        footer={[renderFooter]}
      >
        {props.children}
      </Modal>
    );
  }
}
export default withTranslation()(SimpleModal);

SimpleModal.propTypes = {
  handleCancel: PropTypes.any,
  handleOk: PropTypes.any,
  t: PropTypes.func,
  visible: PropTypes.any,
  with: PropTypes.any,
};
