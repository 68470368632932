import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Inicio from "../../components/Inicio/Inicio";
import InicioEpisService from "../../Services/InicioEpisService";
import EntregasEpisService from "../../Services/EntregasEpisService";
import InicioMapper from "../../Services/Mappers/InicioMapper";
import { Modal } from "antd";
import PropTypes from "prop-types";
import InicioActions from "../../store/actions/inicioActions";
import CommonActions from "../../store/actions/commonActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { LoadingPage } from "./../../components/shared";
import ModalErrorDialog from "../../components/shared/ModalErrorDialog/ModalErrorDialog";

class InicioContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //parametros alert de resultado de la operacion
      alert: {
        visible: false,
        message: "",
        description: "",
        type: "",
      },
    };
  }

  async componentDidMount() {
    this.props.actions.commonActions.displayLoading(true);
    const response = await InicioEpisService.obtenerTareasPendientesByIdEmpleado();
    if (response.isSuccess) {
      const data = InicioMapper.jsonToTareasPendientesObjectList(response.data);
      this.props.actions.inicioActions.updateTareasPendientesEmpleado(data);
    } else if (response.errorMessage) {
      Modal.error({
        title: "Error",
        content: response.errorMessage.msg,
      });
    }
    this.props.actions.commonActions.displayLoading(false);
  }

  cambiarEstadoLote = async (notificacion, nuevoEstado) => {
    const { t } = this.props;
    const response = await EntregasEpisService.actualizarEstadoNotificacionById(
      notificacion,
      nuevoEstado
    );
    if (!response.isSuccess) {
      this.setState({
        alert: {
          type: "error",
          visible: true,
          message: t("entregasEpis.messages.messages7"),
          description: `${t("entregasEpis.messages.messages2")} ${notificacion}`,
        },
      });
      this.delayCloseAlertComponent();
    }
    return response;
  };

  delayCloseAlertComponent() {
    setTimeout(
      function () {
        this.setState({
          alert: {
            visible: false,
            message: "",
            description: "",
            type: "",
          },
        });
      }.bind(this),
      5000
    );
  };

  handleClose = () => {
    this.setState({ alert: { visible: false } });
  };

  render() {
    if (!this.props.isLoading) {
      return (
        <> {
          this.state.alert.visible ? (
            <ModalErrorDialog
              closable
              message={this.state.alert.message}
              description={this.state.alert.description}
              type={this.state.alert.type}
              showIcon
              afterClose={this.handleClose}
            />
          ) : null
        }
          <Inicio
            listadoAvisos={[]}
            listadoTarjetas={[]}
            listadoTareasPendientes={this.props.tareasPendientes.tareas}
            cambiarEstadoLote={this.cambiarEstadoLote.bind()}
          />
        </>
      );
    } else {
      return <LoadingPage />;
    }
  }
}
InicioContainer.propTypes = {
  actions: PropTypes.shape({
    inicioActions: PropTypes.shape({
      updateTareasPendientesEmpleado: PropTypes.func,
    }),
    commonActions: PropTypes.shape({
      displayLoading: PropTypes.func,
    }),
  }),
  tareasPendientes: PropTypes.shape({
    tareas: PropTypes.any,
  }),
  isLoading: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    tareasPendientes: state.tareasPendientesState,
    isLoading: state.commonState.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      inicioActions: bindActionCreators(InicioActions, dispatch),
      commonActions: bindActionCreators(CommonActions, dispatch),
    },
  };
};

const redux = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InicioContainer));

export default withTranslation()(redux);
