import ApiService from "../Api/ApiService";
import { instanceEmpleados } from "../Api/Instance";
import { utils } from "../utils/helpers";
import apiUrl from "../Api/ApiUrl";

class InicioEpisService {
  constructor() {
    this.apiService = new ApiService(instanceEmpleados);
  }

  /**
   * Devuelve las tareas pendientes del Empleado a partir de su identificador.
   * @param {*} idEmpleado
   */
  async obtenerTareasPendientesByIdEmpleado() {
    const url = apiUrl.empleadoServices.obtenerTareasPendientesByIdEmpleado;
    let response = await this.apiService.invokeGET(url);
    response = utils.generateResponseObject(response);
    return response;
  }
}
export default new InicioEpisService();
