import { combineReducers } from "redux";
import { commonReducer } from "./commonReducer";
import { historicoEpisReducer } from "./historicoEpisReducer";
import { entregasEpisReducer } from "./entregasEpisReducer";
import { inicioEpisReducer } from "./inicioEpisReducer";
import { REINICIAR_ESTADO_STORE } from "../constants/StoreConstants";

const appReducer = combineReducers({
  state: (state = {}) => state,
  commonState: commonReducer,
  historicoEpisEmpleadosState: historicoEpisReducer,
  episPendientesEmpleadosState: entregasEpisReducer,
  tareasPendientesState: inicioEpisReducer,
});

const rootReducer = (state, action) => {
  // Borramos todos los datos del storage redux y lo dejamos iniciado de nuevo
  if (action.type === REINICIAR_ESTADO_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
