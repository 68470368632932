/* eslint-disable */
import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Row, Col, Layout, ConfigProvider } from "antd";
import Spanish from "antd/lib/locale/es_ES";
import { Header } from "correos-arq-header";
import { MLateral, BurgerBtn } from "correos-arq-mlateral";
import { MHorizontal } from "correos-arq-mhorizontal";
import { BreadCrumb } from "correos-arq-breadcrumb";
import { PrivateRoute, Login } from "correos-arq-auth";
import AppContextProvider from "./store/context/appContextProvider";
import StoreApp from "./store/StoreApp";
import { storeArq } from "correos-arq-utils";
import pathRoutes from "./router/routes";
import rolesWeb from "./router/rolesweb";
import roleswebCorreosID from "./router/roleswebCorreosID";
import instance from "./Api/Instance";
import i18n from "./i18nConfig/i18n";
import { Provider } from "react-redux";
import ModalErrorDialog from "./components/shared/ModalErrorDialog/ModalErrorDialog";
import { utils } from "./utils/helpers";

// Declararación del Content del Layout de antd
const { Content } = Layout;
const styles = {
  HomeStyle: {
    margin: "0.357em",
  },
};
const createHistory = require("history").createBrowserHistory;

storeArq.load();
class App extends Component {
  constructor(props) {
    super(props);
    require("dotenv").config();
    this.state = {
      showMenu: false, //Inicializa con el menu lateral abierto
      auth: storeArq.data.auth,
      areWeHome: true, //Indica si se esta en "/" para renderizar un estilo u otro
      reload: false, //Recargar app para actualizar submenus
      setInfo: false, //Indica cuando se pulsa el boton hamburguesa
    };
  }

  setInfoBtn = (info) => {
    const { showMenu } = this.state;
    showMenu && this.setState({ setInfo: info, showMenu: false });
    !showMenu && this.setState({ setInfo: info, showMenu: true });
  };

  UNSAFE_componentWillMount = () => {
    if (window.location.search !== "undefined") {
      let id_Token = window.location.search;
      storeArq.data.idTokenNoVal = id_Token;
    }
    if (window.location.pathname === "/login") {
      storeArq.data.urlOrigen = "/";
    } else {
      storeArq.data.urlOrigen = window.location.pathname;
    }
    storeArq.save();

    // evita pantalla en blanco si hay algún error al recuperar el token
    if (
      storeArq.data.auth.userInfo === undefined &&
      storeArq.data.idTokenNoVal !== ""
    ) {
      while (window.location.pathname !== "/login") {
        const history = createHistory({ forceRefresh: false });
        history.push("/login");
      }
    }
  };

  /* *
   * Conseguir rutas completas, Ej. '/avisos/Submenu2/Submenu4'
   * desenvolviendo objeto con arrays anidados
   * */
  getRoutes = (jsonArr, parentRoute) => {
    let parents = jsonArr.map((item) => {
      return parentRoute + item.path;
    });
    let childs = [];
    childs = jsonArr.map(({ path, sub }) => {
      if (sub && sub.length > 0) return this.getRoutes(sub, parentRoute + path);
    });
    childs = childs.flat(Infinity);
    return [...parents, ...childs].filter((item) => item !== undefined);
  };

  Loader = () => (
    <div className="App">
      <div>loading...</div>
    </div>
  );
  clickHome = (key) => {
    this.setState({ areWeHome: key });
  };
  /* *
   * Muestra el menú o lo oculta
   * según el estado rederizado al hacer click al botón
   * */
  clickMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  clickMenuH = () => {
    const { showMenu } = this.state;
    !showMenu && this.setState({ showMenu: true });
  };

  /* *
   * Preparar el prop a pasar al MLateral
   * añadiendo label a los objetos de SubMenu
   * */
  myRecursiveMap = (parent, subMenu) => {
    let res = subMenu.map((item) => {
      if (item.sub && item.sub.length > 0) {
        return {
          label: pathRoutes.filter(({ path, label }) => path === parent + item.path)[0]
            .label,
          path: item.path,
          sub: this.myRecursiveMap(item.sub),
        };
      } else {
        return {
          label: pathRoutes.filter(({ path, label }) => path === parent + item.path)[0]
            .label,
          path: item.path,
          sub: null,
        };
      }
    });
    return res;
  };

  /* *
   * Se usa en migasDePan para lanzar el render de la aplicacion por cambio de url
   * cambiando los submenus
   * */
  reloadApp = () => {
    this.setState({ reload: !this.state.reload });
  };

  render() {
    const { t } = this.props;
    const { showMenu } = this.state;
    storeArq.load();
    let subMenu = this.state.auth.userInfo.subMenu || {
      childs: [],
      parent: "",
    };
    let menuLateral = "";
    let migasDePan = "";
    let menuPPal = "";
    let menuLatBoton = null;
    let routes = [];
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    const todosRoles = rolesWeb.map((r) => r.rol);
    const accesoDenegado = !utils.isEmptyObject(storeArq.data.auth.userInfo) && !todosRoles.includes(storeArq.data.auth.userInfo.rolReal);

    //Array de Strings con todas las rutas completas posibles segun accionesWeb
    if (storeArq.data.auth.token !== "") {
      routes = this.getRoutes(storeArq.data.auth.userInfo.accionesWeb, "");
    } else {
      routes = this.getRoutes(pathRoutes, "");
    }
    //objeto pathRoutes pero con path completo
    let newPathRoutes = routes.map((totalPath) => {
      let item = pathRoutes.filter((items) => items.path === totalPath);
      let res = {
        component: item[0].component,
        path: totalPath,
        label: item[0].label,
      };
      return res;
    });
    //Añadir Home o '/'
    // y rutas internas no expuestas para hacer la posibilidad de hacer un redirect interno.
    newPathRoutes = [
      ...newPathRoutes,
      ...pathRoutes.filter((item) => item.path === "/"),
      ...pathRoutes.filter((item) => item.public === false),
    ];

    //Mejorar estructura de SubMenu si existe en la storeArq y mapear para introducir label
    subMenu.childs = this.myRecursiveMap(subMenu.parent, subMenu.childs || []);

    //Comprobar si hay submenus para renderizar el boton de menu lateral
    subMenu.childs.length > 0
      ? (menuLatBoton = (
        <BurgerBtn showMenu={showMenu} setInfoBtn={this.setInfoBtn} />
      ))
      : (menuLatBoton = null);

    if (this.state.auth.token === "" || window.location.pathname === "/login") {
      menuPPal = <div className="MHorizontal" />;
    } else {
      menuPPal = (
        <MHorizontal
          items={pathRoutes}
          pathRoutes={pathRoutes}
          clickMenu={this.clickMenuH}
          areWeHome={this.state.areWeHome}
          clickHome={this.clickHome}
        />
      );

      if (showMenu) {
        menuLateral = (
          <Row>
            <Col lg={4} style={{ width: "auto" }}>
              <MLateral
                longItem="20"
                items={subMenu}
                reloadApp={this.reloadApp}
              />
            </Col>
          </Row>
        );
        //Necesario pasar pathname como props aunque no se utilice
        //Sirve para renderizar otra vez las migas cuando se cambia la url
        migasDePan = (
          <Row>
            <Col span={1} className="ContainBtn">
              {menuLatBoton}
            </Col>
            <BreadCrumb
              clickHome={this.clickHome}
              path={window.location.pathname}
              clickMenu={showMenu && this.clickMenu}
              routes={newPathRoutes.map((item) => ({
                path: item.path,
                label: item.label,
              }))}
              reloadApp={this.reloadApp}
            />
          </Row>
        );
      } else {
        menuLateral = (
          <Row>
            <Col span={1}>{menuLatBoton}</Col>
            <BreadCrumb
              clickHome={this.clickHome}
              path={window.location.pathname}
              clickMenu={showMenu && this.clickMenu}
              routes={newPathRoutes}
              reloadApp={this.reloadApp}
            />
          </Row>
        );
      }
    }
    return (
      <Suspense fallback={<this.Loader />}>
        <Provider store={StoreApp}>
          <AppContextProvider>
            <ConfigProvider locale={Spanish}>
              <Router>
                <div className="App">
                  <Layout className="layout">
                    <Header
                      clickHome={this.clickHome}
                      clickMenu={showMenu && this.clickMenu}
                      i18n={i18n}
                      logoutSys={false}
                    />
                    <Layout className="layout">{menuPPal}</Layout>
                    <Layout className="layout">
                      <Row>
                        <Col span={1} />
                        <Col lg={22}>
                          <Layout className="layout">
                            {menuLateral}
                            <Layout className="layout">
                              {migasDePan}
                              <Content
                                className={
                                  showMenu === true
                                    ? "ContentSpace"
                                    : "Contenido"
                                }
                                style={
                                  window.location.pathname === "/" ||
                                    window.location.pathname === "/salidadatos" ||
                                    window.location.pathname === "/RutaPrivada"
                                    ? styles.HomeStyle
                                    : null
                                }
                              >
                                {isIE ?
                                  <ModalErrorDialog
                                    message="Advertencia"
                                    description="La aplicación no es compatible con Internet Explorer. Es necesario utilizar otro navegador."
                                    type="warning"
                                  />
                                  : null}
                                <Switch>
                                  {accesoDenegado ?
                                    <ModalErrorDialog
                                      message="Error"
                                      description="No tiene permisos para acceder a la aplicación."
                                      type="error"
                                    /> :
                                    newPathRoutes.map(({ component, path }) => (
                                      <PrivateRoute
                                        exact
                                        key={path}
                                        path={path}
                                        component={component}
                                        auth={this.state.auth}
                                      />
                                    ))
                                  }
                                  <Route
                                    path="/login"
                                    render={(props) => (
                                      <Login
                                        rolesWeb={rolesWeb}
                                        rolesWebCorreosID={roleswebCorreosID}
                                        instance={instance}
                                        tipo_integracion="isam"
                                        {...props}
                                      />
                                    )}
                                  />
                                </Switch>
                              </Content>
                            </Layout>
                          </Layout>
                        </Col>
                        <Col span={1} />
                      </Row>
                    </Layout>
                  </Layout>
                </div>
              </Router>
            </ConfigProvider>
          </AppContextProvider>
        </Provider>
      </Suspense>
    );
  }
}

export default App;
