import * as React from "react";

function sgid(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 262.36 299.67"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:#ffcd00}.prefix__cls-3{fill:none;stroke:#002e6d;stroke-width:3.12px;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__Capa_1-2" data-name="Capa 1">
          <path
            fill="#fff"
            d="M129.85 283.69L15.38 216.98l.33-133.72 116.8-67.01 114.46 67.71-.32 132.72-116.8 67.01z"
          />
          <path
            className="prefix__cls-2"
            d="M162.67 158.13a72.63 72.63 0 00-22.73-6.5q.41-2.79.79-5.57c1.75-1.13 3-2.78 3.12-4.14.21-1.73.39-3.44.59-5.18a1.45 1.45 0 001 .47 1.51 1.51 0 001.64-1.35l.82-7.94a1.47 1.47 0 00-1.34-1.64 1.29 1.29 0 00-.87.15c.17-1.51.36-3 .51-4.55.15-2.07 1.18-7.62-2.82-11.19-5.79-5.68-18.53-5.68-24.33 0-4 3.57-3 9.12-2.83 11.19.19 1.52.36 3 .53 4.55a1.3 1.3 0 00-.87-.15 1.47 1.47 0 00-1.34 1.64l.83 7.94a1.5 1.5 0 001.64 1.35 1.44 1.44 0 00.94-.47c.19 1.74.41 3.45.61 5.18.11 1.36 1.37 3 3.1 4.14q.41 2.77.79 5.57a72.53 72.53 0 00-22.7 6.5A7.6 7.6 0 0096 165c.27 2.79.54 5.59.85 8.39a6.31 6.31 0 004.65 5.32 185.87 185.87 0 0059.36 0 6.38 6.38 0 004.66-5.32c.29-2.8.56-5.6.83-8.39a7.62 7.62 0 00-3.68-6.82M131.18 266v-41.87m0-148.77V33.49m-99.4 174l34.05-19.64M195.35 113l34.84-20.1m2.48 115.75L196.85 188M67.92 113.55l-34.62-20m-1.79-19.12l84.8-48.95m29.31-.25l85.47 49.35m15.43 26v99.86M232 225.08l-86.71 50.06m-28.65-.29l-85.37-49.27m-15.44-25.66V98.55M146.81 282a16.14 16.14 0 11-16.14-16.14A16.13 16.13 0 01146.81 282"
          />
          <path
            className="prefix__cls-3"
            d="M131.18 266v-41.87m0-148.77V33.49m-99.4 174l34.05-19.64M195.35 113l34.84-20.1m2.48 115.75L196.85 188M67.92 113.55l-34.62-20m-1.79-19.12l84.8-48.95m29.31-.25l85.47 49.35m15.43 26v99.86M232 225.08l-86.71 50.06m-28.65-.29l-85.37-49.27m-15.44-25.66V98.55M146.81 282a16.14 16.14 0 11-16.14-16.14A16.13 16.13 0 01146.81 282z"
          />
          <path
            d="M161.6 158.4a72.66 72.66 0 00-22.72-6.51q.41-2.79.78-5.58c1.75-1.12 3-2.76 3.12-4.12.21-1.74.4-3.45.6-5.2a1.39 1.39 0 001 .48 1.51 1.51 0 001.65-1.34l.82-7.95a1.48 1.48 0 00-1.34-1.64 1.38 1.38 0 00-.88.15c.18-1.51.36-3 .52-4.55.15-2.07 1.17-7.63-2.83-11.19-5.78-5.68-18.53-5.68-24.32 0-4 3.56-3 9.12-2.84 11.19.2 1.52.36 3 .54 4.55a1.38 1.38 0 00-.87-.15 1.49 1.49 0 00-1.35 1.64l.83 7.95a1.49 1.49 0 001.65 1.34 1.46 1.46 0 00.94-.48c.18 1.75.41 3.46.61 5.2.1 1.36 1.37 3 3.09 4.12q.4 2.79.79 5.58a72.55 72.55 0 00-22.7 6.51 7.61 7.61 0 00-3.71 6.81c.28 2.8.55 5.6.85 8.39a6.31 6.31 0 004.66 5.32 185.87 185.87 0 0059.36 0 6.35 6.35 0 004.66-5.32c.28-2.79.55-5.59.83-8.39a7.61 7.61 0 00-3.74-6.81zM131.18 225a74.07 74.07 0 1174.06-74 74.07 74.07 0 01-74.06 74z"
            strokeLinejoin="bevel"
            fill="none"
            stroke="#002e6d"
            strokeWidth={3.12}
          />
          <path
            className="prefix__cls-2"
            d="M260.8 217.42a16.14 16.14 0 11-16.15-16.14 16.15 16.15 0 0116.15 16.14m-227 0a16.14 16.14 0 11-16.15-16.14 16.14 16.14 0 0116.15 16.14m113-199.72a16.14 16.14 0 11-16.13-16.14 16.14 16.14 0 0116.14 16.14m114 66.57a16.14 16.14 0 11-16.15-16.13 16.16 16.16 0 0116.14 16.13m-227 0a16.14 16.14 0 11-16.11-16.13 16.15 16.15 0 0116.15 16.13"
          />
          <path
            className="prefix__cls-3"
            d="M260.8 217.42a16.14 16.14 0 11-16.15-16.14 16.15 16.15 0 0116.15 16.14zm-227 0a16.14 16.14 0 11-16.15-16.14 16.14 16.14 0 0116.19 16.14zm113-199.72a16.14 16.14 0 11-16.13-16.14 16.14 16.14 0 0116.14 16.14zm114 66.57a16.14 16.14 0 11-16.15-16.13 16.16 16.16 0 0116.15 16.13zm-227 0a16.14 16.14 0 11-16.11-16.13 16.15 16.15 0 0116.15 16.13z"
          />
        </g>
      </g>
    </svg>
  );
}

export default sgid;
