import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert, Icon } from "antd";
import { withTranslation } from "react-i18next";

const levelTypes = {
  success: {
    label: "EXITO",
    color: "#008000",
    backgroundColor: "#EDFFE1",
    type: "success",
    title: "Confirmación",
    icon: <Icon type="check-circle" theme="filled" style={{ color: "#008000" }} />
  },
  error: {
    label: 'ERROR',
    color: "#CC0000",
    backgroundColor: "#FFF1F0",
    type: "error",
    title: "Error",
    icon: <Icon type="close-circle" theme="filled" style={{ color: "#CC0000" }} />
  },
  warning: {
    label: 'WARNING',
    color: "#AA2E00",
    backgroundColor: "#FFFFCC",
    type: "warning",
    title: "Advertencia",
    icon: <Icon type="exclamation-circle" theme="filled" style={{ color: "#AA2E00" }} />
  },
  info: {
    label: 'INFO',
    color: "#005CE3",
    backgroundColor: "#EFF6FF",
    type: "info",
    title: "Información",
    icon: <Icon type="info-circle" theme="filled" style={{ color: "#005CE3" }} />
  }
}

class ModalErrorDialog extends Component {
  render() {
    return (
      < Alert
        closable={this.props.closable}
        showIcon={true}
        afterClose={this.props.afterClose}
        message={
          < span
            style={{
              color: levelTypes[this.props.type]?.color,
              fontWeight: 600
            }
            }
          >
            {this.props.t(levelTypes[this.props.type]?.title)}
          </span >
        }
        description={this.props.description}
        type={levelTypes[this.props.type]?.type}
        icon={levelTypes[this.props.type]?.icon}
        maskStyle={{ color: levelTypes[this.props.type]?.color }}
        style={{
          backgroundColor: levelTypes[this.props.type]?.backgroundColor,
          color: levelTypes[this.props.type]?.color,
          borderColor: levelTypes[this.props.type]?.color,
          marginBottom: "20px"
        }}
      />
    );
  }
}

export default withTranslation()(ModalErrorDialog);

ModalErrorDialog.propTypes = {
  message: PropTypes.any,
  description: PropTypes.any,
  type: PropTypes.any,
};
