import {
  Button,
  Card,
  Checkbox,
  Col,
  Collapse,
  Icon,
  List,
  Table,
  Row,
  Typography,
} from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { storeArq } from "correos-arq-utils";
import { withTranslation } from "react-i18next";
import { ESTADO_LOTE } from "../../utils/constants";
import { utils } from "../../utils/helpers";
import SimpleModal from "../shared/ModalDialog/SimpleModal";
import "./Entregas.css";

const { Paragraph, Text } = Typography;

class Entregas extends Component {
  constructor(props) {
    super(props);
    let entregasPendientes = ["0"];
    if (!_.isEmpty(props.listadoEpisPendientesEmpleados)) {
      entregasPendientes = props.listadoEpisPendientesEmpleados
        .filter((e) => _.isEqual(e.estado.code, ESTADO_LOTE.PENDIENTE.codigo))
        .map((e) => e.codLote)
    };

    this.state = {
      minValue: 0,
      maxValue: 100,
      activeKey: this.props.entregaSelecionada || entregasPendientes,
      disabledButtons: Array.from({
        length: this.props.listadoEpisPendientesEmpleados?.length,
      }).fill(true),
      //parametros modal de confirmacion
      simpleModal: {
        visible: false,
        codigoLote: "",
        estadoLote: "",
      },
    };
  }

  changePanel = async (key) => {
    let expand = true;
    // Comprobamos el nuevo lote seleccionado:
    const nuevo = key.filter((k) => !this.state.activeKey.includes(k));

    // Buscamos el lote en la lista para poder consultar el resto de propiedades:
    const lote = this.props.listadoEpisPendientesEmpleados.find((e) =>
      _.isEqual(e.codLote, nuevo[0])
    );
    // Si el estado del lote es "Nueva", llamamos al BE para marcarla como "Pendiente":
    if (
      !_.isEmpty(lote) &&
      _.isEqual(lote.estado.code, ESTADO_LOTE.NO_LEIDO.codigo)
    ) {
      const response = await this.props.cambiarEstadoLote(nuevo[0], {
        statusCode: ESTADO_LOTE.PENDIENTE.codigo,
      });
      console.log("response:", response);
      expand = response.isSuccess;
    }
    if (expand) {
      this.setState({
        activeKey: key,
      });
    }
  };

  cambiarEstadoLote = async (codLote, estado) => {
    this.setState({
      simpleModal: {
        visible: false,
        codigoLote: "",
        estadoLote: "",
      },
    });
    this.props.cambiarEstadoLote(codLote, {
      statusCode: estado.codigo,
      statusDesc: estado.descripcion,
    });
  };

  /* cuando aceptamos los terminos pasamos el indice y el valor del check
  para habilitar o deshabilitar posteriormente su correspondiente boton de aceptar probando */
  aceptarTerminos = async (index, e) => {
    this.setState((oldState) => {
      const newDisabledButtons = [...oldState.disabledButtons];
      //los que estan deshabilitados
      newDisabledButtons[index] = !e;
      return {
        disabledButtons: newDisabledButtons,
      };
    });
  };

  renderCuerpoMensaje() {
    const { t } = this.props;
    const listaMensajes = [];
    const numeroFrases = 8;
    for (let i = 1; i < numeroFrases; i++) {
      listaMensajes.push(
        <Paragraph key={i}>
          {t(`entregasEpis.cuerpoMensaje.${i}`)}
        </Paragraph>
      );
    }
    return listaMensajes;
  }

  renderContenidoEntrega(val, index) {
    const { t } = this.props;
    return (
      <Row type="flex">
        <Col span={10}>
          <Typography
            style={{
              textAlign: "justify",
              paddingRight: 20,
              paddingLeft: 10,
            }}
          >
            <Paragraph>
              {t("entregasEpis.labels.fechaGrabacionEntrega")}
              <Text strong>: {utils.stringToDate(val.fechaCreacion)}</Text>
            </Paragraph>
            <Paragraph>
              {t("entregasEpis.labels.listadoEpisCorrespondientesEntrega")}
              {val.codLote}:
            </Paragraph>
            <Paragraph>
              <div
                style={{
                  marginInline: "auto",
                }}
              >
                <List
                  itemLayout="horizontal"
                  dataSource={val.epis}
                  renderItem={(item) => (
                    <List.Item>
                      <List.Item.Meta
                        title={`${item.code} - ${item.description} - ${item.modality?.description}`}
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Paragraph>
          </Typography>
        </Col>
        <Col span={14}>
          <Typography
            style={{
              textAlign: "justify",
              paddingRight: 10,
            }}
          >
            <Paragraph>{t("entregasEpis.cuerpoMensaje.0")}</Paragraph>
          </Typography>
          <Typography
            style={{
              textAlign: "justify",
              paddingRight: 10,
              paddingLeft: 15,
            }}
          >
            {this.renderCuerpoMensaje()}
            <Paragraph>
              <Checkbox
                id="check-aceptar-terminos"
                autoFocus={true}
                onChange={(e) =>
                  //pasamos el indice y el valor del check de aceptar terminos
                  this.aceptarTerminos(index, e.target.checked)
                }
              >
                <Text strong>{t("entregasEpis.labels.aceptarTerminos")}</Text>
              </Checkbox>
            </Paragraph>
          </Typography>
        </Col>
      </Row>
    );
  }

  renderDatosEntregas() {
    const { t } = this.props;
    const dataLotes = this.props.listadoEpisPendientesEmpleados;
    const usuarioIsam = `${storeArq.data.auth.userInfo.nombre} ${storeArq.data.auth.userInfo.apellidos}`;
    const gutter = 5;
    const { Panel } = Collapse;

    return (
      <>
        <Row gutter={[gutter, gutter]} style={{ marginBottom: 10, marginTop: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2>
              {t("entregasEpis.title")}- {usuarioIsam}
            </h2>
          </Col>
        </Row>
        <Row gutter={gutter} style={{ marginBottom: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="entregas">
            <Collapse
              id="collapse-panel"
              activeKey={this.state.activeKey}
              onChange={this.changePanel}
            >
              {dataLotes.length === 0 && (
                <Panel header="Entrega de EPI's" key="0">
                  <Row>
                    <Col span={24}>
                      <Table />
                    </Col>
                  </Row>
                </Panel>
              )}
              {dataLotes &&
                dataLotes.length > 0 &&
                dataLotes
                  .slice(this.state.minValue, this.state.maxValue)
                  .map((val, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Panel header={<b>{val.asunto}</b>} key={val.codLote}>
                      <Card
                        id="seccion-entrega"
                        size="small"
                        style={{ margin: 10, backgroundColor: "#fafafa" }}
                        actions={[
                          // eslint-disable-next-line react/jsx-key
                          <Button
                            id="boton-rechazar-lote"
                            type="default"
                            onClick={() => {
                              this.setState({
                                simpleModal: {
                                  visible: true,
                                  codigoLote: val.codLote,
                                  estadoLote: ESTADO_LOTE.RECHAZADO,
                                },
                              });
                            }}
                          >
                            <Icon type="close" />
                            {t("entregasEpis.buttons.rechazar")}
                          </Button>,
                          // eslint-disable-next-line react/jsx-key
                          <Button
                            id="boton-aceptar-lote"
                            disabled={this.state.disabledButtons[index]}
                            type="primary"
                            onClick={() =>
                              this.cambiarEstadoLote(
                                val.codLote,
                                ESTADO_LOTE.ACEPTADO
                              )
                            }
                          >
                            <Icon type="check" />
                            {t("entregasEpis.buttons.aceptar")}
                          </Button>,
                        ]}
                      >
                        {this.renderContenidoEntrega(val, index)}
                      </Card>
                    </Panel>
                  ))}
            </Collapse>
          </Col>
        </Row>
        <SimpleModal
          id="modal-confirmacion"
          visible={this.state.simpleModal.visible}
          handleOk={() => {
            this.cambiarEstadoLote(
              this.state.simpleModal.codigoLote,
              this.state.simpleModal.estadoLote
            );
          }}
          handleCancel={() => {
            this.setState({
              simpleModal: {
                visible: false,
                codigoLote: "",
                estadoLote: "",
              },
            });
          }}
          width={"65%"}
        >
          <Typography>
            <Paragraph>
              <Row
                gutter={gutter}
                type="flex"
                justify="space-around"
                align="middle"
              >
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  {" "}
                  <Icon
                    type="exclamation-circle"
                    theme="filled"
                    style={{
                      color: "#FF4500",
                      fontSize: "60px",
                    }}
                  />
                </Col>
                <Col
                  xs={18}
                  sm={18}
                  md={18}
                  lg={18}
                  xl={18}
                  style={{ textAlign: "" }}
                >
                  {" "}
                  <Text strong style={{ fontSize: "16px" }}>
                    {t("entregasEpis.messages.messages4")}{" "}
                    {this.state.simpleModal.codigoLote}
                    {t("entregasEpis.messages.messages5")}
                  </Text>
                </Col>
              </Row>
            </Paragraph>
          </Typography>
        </SimpleModal>
      </>
    );
  }
  render() {
    return <div className="sectionContainer">{this.renderDatosEntregas()}</div>;
  }
}

Entregas.propTypes = {
  cambiarEstadoLote: PropTypes.func,
  entregaSelecionada: PropTypes.any,
  listadoEpisPendientesEmpleados: PropTypes.array,
  t: PropTypes.func,
};

export default withTranslation()(Entregas);
