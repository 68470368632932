import { Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { storeArq } from "correos-arq-utils";
import _ from "lodash";
import TablaHistorico from "./tabla/TablaHistorico";
import "./Historico.css";

class Historico extends Component {
  renderDatosHistorico() {
    const { t } = this.props;
    const usuarioIsam = `${storeArq.data.auth.userInfo.nombre} ${storeArq.data.auth.userInfo.apellidos}`;
    const gutter = 5;

    let maxY = undefined;
    let maxX = undefined;
    if (!_.isEmpty(this.props.listadoHistoricoEpisEmpleados)) {
      maxX = 1500;
      const maxResults = 5;
      if (this.props.listadoHistoricoEpisEmpleados?.length > maxResults) {
        maxY = 450;
      }
    }

    return (
      <div className="historico">
        <Row gutter={[gutter, gutter]} style={{ marginBottom: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <h2>
              {t("historicoEpis.title")}- {usuarioIsam}
            </h2>
          </Col>
        </Row>
        <Row>
        </Row>
        <Row>
          <Col>
            <Layout>
              <Layout.Content style={{ backgroundColor: "#FFFFFF" }}>
                <TablaHistorico
                  dataSource={this.props.listadoHistoricoEpisEmpleados}
                  isLoading={this.props.isLoading}
                  scroll={{ x: maxX, y: maxY }}
                  rowKey="id" />
              </Layout.Content>
            </Layout>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      <div className="sectionContainer">{this.renderDatosHistorico()}</div>
    );
  }
}

Historico.propTypes = {
  isLoading: PropTypes.any,
  listadoHistoricoEpisEmpleados: PropTypes.any,
  refresh: PropTypes.any,
  t: PropTypes.func,
};

export default withTranslation()(Historico);
