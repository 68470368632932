import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class CountLabel extends Component {
  render() {
    return (
      <Row type="flex" justify="center">
        <Col>
          <span>{this.props.label}</span>
        </Col>
        {this.props.count > 0 && (
          <Col className="circleContainer">
            <div className="circulo">
              <span>{this.props.count}</span>
            </div>
          </Col>
        )}
      </Row>
    );
  }
}

CountLabel.propTypes = {
  count: PropTypes.number,
  label: PropTypes.any,
};

export default withTranslation()(CountLabel);
