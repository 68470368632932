import { Modal } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Historico from "../../components/Historico/Historico";
import HistoricoMapper from "../../Services/Mappers/HistoricoMapper";
import HistoricoEpisService from "../../Services/HistoricoEpisService";
import HistoricoEpisActions from "../../store/actions/historicoEpisActions";
import CommonActions from "../../store/actions/commonActions";
import { LoadingPage } from "./../../components/shared";

class HistoricoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    this.props.actions.commonActions.displayLoading(true);
    const response = await HistoricoEpisService.obtenerHistoricoEpisByIdEmpleado();
    var data = [];
    if (response.isSuccess) {
      data = await HistoricoMapper.jsonToHistoricoEmpleadoObjectList(
        response.data
      );
    } else if (response.errorMessage) {
      Modal.error({
        title: "Error",
        content: response.errorMessage.msg,
      });
    }
    this.props.actions.historicoActions.updateDatosHistoricoEmpleados(
      data
    );
    this.props.actions.commonActions.displayLoading(false);
  }

  render() {
    if (!this.props.isLoading) {
      return (
        <Historico
          listadoHistoricoEpisEmpleados={
            this.props.historicoEpis.historicoEpisEmpleados
          }
        />
      );
    } else {
      return <LoadingPage />;
    }
  }
}

HistoricoContainer.propTypes = {
  actions: PropTypes.shape({
    historicoActions: PropTypes.shape({
      updateDatosHistoricoEmpleados: PropTypes.func,
    }),
    commonActions: PropTypes.shape({
      displayLoading: PropTypes.func,
    }),
  }),
  historicoEpis: PropTypes.shape({
    historicoEpisEmpleados: PropTypes.any,
  }),
  isLoading: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    historicoEpis: state.historicoEpisEmpleadosState,
    isLoading: state.commonState.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      historicoActions: bindActionCreators(
        HistoricoEpisActions,
        dispatch
      ),
      commonActions: bindActionCreators(CommonActions, dispatch),
    },
  };
};

const redux = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HistoricoContainer));

export default withTranslation()(redux);
