import * as React from "react";

function forma2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 110.62 109.14"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1{fill:#fff}.prefix__cls-2,.prefix__cls-5{fill:none;stroke:#002e6d;stroke-width:1.41px}.prefix__cls-2{stroke-miterlimit:10}.prefix__cls-3{fill:#002e6d}.prefix__cls-4{fill:#ffcd00}.prefix__cls-5{stroke-linecap:round;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__Capa_1-2" data-name="Capa 1">
          <path
            className="prefix__cls-1"
            d="M97.09 15.74A6.79 6.79 0 1190.3 9a6.79 6.79 0 016.79 6.79"
          />
          <path
            className="prefix__cls-2"
            d="M97.09 15.74A6.79 6.79 0 1190.3 9a6.79 6.79 0 016.79 6.74z"
          />
          <path
            className="prefix__cls-3"
            d="M91.41 15.74a1.11 1.11 0 11-1.11-1.11 1.11 1.11 0 011.11 1.11"
          />
          <path
            className="prefix__cls-1"
            d="M97.32 91a7 7 0 11-7-7 7 7 0 017 7"
          />
          <path
            className="prefix__cls-2"
            d="M97.32 91a7 7 0 11-7-7 7 7 0 017 7z"
          />
          <path
            className="prefix__cls-3"
            d="M91.41 91a1.11 1.11 0 11-1.11-1.11A1.11 1.11 0 0191.41 91"
          />
          <path
            className="prefix__cls-2"
            d="M55.22 83.93h10.26l7.71-19.06h25.14M55.22 21.31h10.26l7.71 19.06h25.14"
          />
          <path
            className="prefix__cls-4"
            d="M109.91 65a7.27 7.27 0 11-7.26-7.27 7.27 7.27 0 017.26 7.27"
          />
          <path
            className="prefix__cls-2"
            d="M109.91 65a7.27 7.27 0 11-7.26-7.27 7.27 7.27 0 017.26 7.27z"
          />
          <path
            className="prefix__cls-3"
            d="M103.76 65a1.11 1.11 0 11-1.11-1.11 1.11 1.11 0 011.11 1.11"
          />
          <path
            className="prefix__cls-4"
            d="M109.23 40.36a6.58 6.58 0 11-6.58-6.58 6.58 6.58 0 016.58 6.58"
          />
          <circle
            className="prefix__cls-2"
            cx={102.65}
            cy={40.36}
            r={6.58}
            transform="rotate(-22.5 102.639 40.354)"
          />
          <path
            className="prefix__cls-3"
            d="M103.76 40.36a1.11 1.11 0 11-1.11-1.1 1.11 1.11 0 011.11 1.1"
          />
          <path className="prefix__cls-5" d="M55.47 98.46V8.95" />
          <path
            className="prefix__cls-4"
            d="M55.49.71a5.57 5.57 0 105.57 5.57A5.57 5.57 0 0055.49.71"
          />
          <path
            className="prefix__cls-2"
            d="M55.49.71a5.57 5.57 0 105.57 5.57A5.57 5.57 0 0055.49.71z"
          />
          <path
            className="prefix__cls-3"
            d="M55.49 5.56a.72.72 0 10.72.72.72.72 0 00-.72-.72"
          />
          <path
            className="prefix__cls-4"
            d="M70.8 53.16a4.55 4.55 0 104.55-4.54 4.55 4.55 0 00-4.55 4.54"
          />
          <circle
            className="prefix__cls-2"
            cx={75.35}
            cy={53.16}
            r={4.55}
            transform="rotate(-45 75.35 53.16)"
          />
          <path
            className="prefix__cls-3"
            d="M74.63 53.16a.72.72 0 00.72.72.72.72 0 10-.72-.72"
          />
          <path
            className="prefix__cls-4"
            d="M33.27 53.16a4.55 4.55 0 104.55-4.54 4.55 4.55 0 00-4.55 4.54"
          />
          <circle
            className="prefix__cls-2"
            cx={37.82}
            cy={53.16}
            r={4.55}
            transform="rotate(-45 37.813 53.167)"
          />
          <path
            className="prefix__cls-3"
            d="M37.11 53.16a.71.71 0 00.71.72.72.72 0 100-1.43.71.71 0 00-.71.71M20.79 15.74a.72.72 0 10.71-.74.71.71 0 00-.71.72"
          />
          <path
            className="prefix__cls-5"
            d="M56.32 32.86H37.95L24.11 19.02M56.32 74.07H37.95L24.11 87.91"
          />
          <path
            className="prefix__cls-4"
            d="M14.71 15.74A6.8 6.8 0 1021.51 9a6.79 6.79 0 00-6.8 6.79"
          />
          <path
            className="prefix__cls-2"
            d="M14.71 15.74A6.8 6.8 0 1021.51 9a6.79 6.79 0 00-6.8 6.74z"
          />
          <path
            className="prefix__cls-1"
            d="M12.49 91a9 9 0 109-9 9 9 0 00-9 9"
          />
          <path
            className="prefix__cls-2"
            d="M12.49 91a9 9 0 109-9 9 9 0 00-9 9z"
          />
          <path
            className="prefix__cls-3"
            d="M20.4 91a1.11 1.11 0 101.1-1.11A1.11 1.11 0 0020.4 91"
          />
          <path
            className="prefix__cls-2"
            d="M56.58 83.93H46.33l-7.71-19.06H13.47M56.58 21.31H46.33l-7.71 19.06H13.47"
          />
          <path
            className="prefix__cls-4"
            d="M.71 65a8.45 8.45 0 108.45-8.45A8.45 8.45 0 00.71 65"
          />
          <path
            className="prefix__cls-2"
            d="M.71 65a8.45 8.45 0 108.45-8.45A8.45 8.45 0 00.71 65z"
          />
          <path
            className="prefix__cls-3"
            d="M8.05 65a1.11 1.11 0 101.11-1.11A1.11 1.11 0 008.05 65"
          />
          <path
            className="prefix__cls-1"
            d="M2.58 40.36a6.58 6.58 0 106.58-6.58 6.58 6.58 0 00-6.58 6.58"
          />
          <circle
            className="prefix__cls-2"
            cx={9.16}
            cy={40.36}
            r={6.58}
            transform="rotate(-45 9.16 40.366)"
          />
          <path
            className="prefix__cls-3"
            d="M8.05 40.36a1.11 1.11 0 101.11-1.1 1.11 1.11 0 00-1.11 1.1"
          />
          <path
            className="prefix__cls-4"
            d="M55.47 93.81a7.31 7.31 0 107.3 7.31 7.31 7.31 0 00-7.3-7.31"
          />
          <path
            className="prefix__cls-2"
            d="M55.47 93.81a7.31 7.31 0 107.3 7.31 7.31 7.31 0 00-7.3-7.31z"
          />
          <path
            className="prefix__cls-3"
            d="M55.47 100.4a.72.72 0 10.72.72.72.72 0 00-.72-.72"
          />
        </g>
      </g>
    </svg>
  );
}

export default forma2;
