import _ from "lodash";
import { utils } from "../../utils/helpers";

class PendientesMapper {

  jsonToEpisPendientesEmpleadoObject(data) {
    let listadoEpisPendientesProcesado = null;
    if (!utils.isEmptyObject(data)) {
      listadoEpisPendientesProcesado = {
        asunto: data.subject,
        cuerpo: data.body,
        codLote: data.batchCode,
        estado: data.status,
        fechaCreacion: data.creationDate,
        epis: data.ipeList,
      };
    }
    return listadoEpisPendientesProcesado;
  }

  jsonToEpisPendientesEmpleadoObjectList(dataList) {
    let objectList = [];
    if (!_.isEmpty(dataList) && dataList.length > 0) {
      objectList = dataList.map(registro =>
        this.jsonToEpisPendientesEmpleadoObject(registro)
      );
    }
    return objectList;
  }
}
export default new PendientesMapper();
