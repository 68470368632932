import { storeArq } from "correos-arq-utils";
import axios from "axios";
import { auth } from "../Api/ApiUrl";
import { Modal } from "antd";

const createHistory = require("history").createBrowserHistory;

//función para cerrar sesion: borrar la cookie de la persitencia del store y cierra sesión en el ISAM
function handleUrlLogin() {
  sessionStorage.clear();
  if (storeArq.data.tipo_integracion === "INTERN") {
    axios
      .post(auth.logoutISAM)
      .then(() => {
        // Redirección a la página de login:
        const history = createHistory({
          forceRefresh: true
        });
        history.push("/login");
      })
      .catch(() => {
        Modal.error({
          title: "Error Inesperado",
          content: "Se ha producido un error inesperado. Vuelve a intentarlo más tarde.",
        });
      });
  } else {
    try {
      let url = auth.logoutC_ID + "/" + window._env_.REACT_APP_CLIENT_ID_C_ID;
      window.location.href = url;
    } catch (error) {
      Modal.error({
        title: "Error Inesperado",
        content: "Se ha producido un error inesperado. Vuelve a intentarlo más tarde.",
      });
    }
  }
};

export const logout = {
  handleUrlLogin,
};
