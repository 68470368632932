import { Col, Divider, Row, Table } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getColumns } from "./tabla/columnas/InicioTableColumns";
import Alertas from "./components/Alertas";
import "./Inicio.scss";
import Tarjetas from "./components/Tarjetas";
import { storeArq } from "correos-arq-utils";
import { ESTADO_LOTE } from "../../utils/constants";
import { withRouter } from "react-router-dom";

const createHistory = require("history").createBrowserHistory;

class Home extends Component {

  handleClick = async (row, t) => {
    let redirect = true;
    if (row.estado.code === ESTADO_LOTE.NO_LEIDO.codigo) {
      const response = await this.props.cambiarEstadoLote(
        row.id,
        { statusCode: ESTADO_LOTE.PENDIENTE.codigo },
        t
      );
      redirect = response.isSuccess;
    }
    if (redirect) {
      // Redirección:
      const history = createHistory({
        forceRefresh: true
      });
      history.push(`/epis/entregas/${row.id}`);
    }
  };



  render() {
    const { t } = this.props;
    const usuarioIsam = `${storeArq.data.auth.userInfo.nombre} ${storeArq.data.auth.userInfo.apellidos}`;
    const gutter = 5;

    let seccionAvisos = null;
    if (!this.props.listadoAvisos) {
      seccionAvisos = (
        <div>
          <Row gutter={[gutter, gutter]}>
            <h2>{t("inicio.title1")}</h2>
          </Row>
          <Row>
            <Alertas />
          </Row>
          <Divider />
        </div>
      );
    }

    const maxWidth = 400;
    const maxResults = 5;
    let scroll = undefined;
    if (this.props.listadoTareasPendientes?.length > maxResults) {
      scroll = maxWidth;
    }

    const seccionTareas = (
      <div>
        <Row gutter={[gutter, gutter]} style={{ marginBottom: 10 }}>
          <h2>
            {t("inicio.title2")}- {usuarioIsam}
          </h2>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Table
              id="tabla-resultados"
              dataSource={this.props.listadoTareasPendientes || []}
              loading={this.props.isLoading}
              columns={getColumns(this.props.t, this.handleClick)}
              bordered
              pagination={false}
              rowKey="id"
              scroll={{ scroll }} />
          </Col>
        </Row>
        <Divider />
      </div>
    );

    let seccionTarjetas = null;
    if (!this.props.listadoTarjetas) {
      seccionTarjetas = (
        <div>
          <Row gutter={[gutter, gutter]} style={{ marginBottom: 10 }}>
            <h2>{t("inicio.title3")}</h2>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Tarjetas />
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div className="sectionContainer" style={{ marginTop: 10 }}>
        {seccionAvisos}
        {seccionTareas}
        {seccionTarjetas}
      </div>
    );
  }
}

Home.propTypes = {
  isLoading: PropTypes.any,
  listadoTareasPendientes: PropTypes.any,
  refresh: PropTypes.any,
  t: PropTypes.func,
};

export default withTranslation()(withRouter(Home));
