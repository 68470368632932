import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Tarjeta from "../../../components/shared/Cards/Tarjeta";
import Net from "../../../components/shared/svg/sgid";
import Monitor from "../../../components/shared/svg/monitor";
import People from "../../../components/shared/svg/people";
import Item2 from "../../../components/shared/svg/forma2";

class Tarjetas extends Component {
  render() {
    const gutter = 5;

    return (
      <div>
        <Row gutter={[gutter, gutter]} type="flex" justify="space-between">
          <Col xs={24} sm={24} md={12} lg={6} xl={4}>
            <Tarjeta
              cover={<Net className="SizeIcon" />}
              href="https://www.correos.es"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={4}>
            <Tarjeta
              cover={<Monitor className="SizeIcon" />}
              href="https://www.correos.es"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={4}>
            <Tarjeta
              cover={<Item2 className="SizeIcon" />}
              href="https://www.correos.es"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={4}>
            <Tarjeta
              cover={<People className="SizeIcon" />}
              href="https://www.correos.es"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Tarjetas.propTypes = {
  isLoading: PropTypes.any,
  listadoTareasPendientes: PropTypes.any,
  refresh: PropTypes.any,
  t: PropTypes.func,
};

export default withTranslation()(Tarjetas);
