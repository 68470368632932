/*eslint-env node*/

const auth = {
  getTokenISAM:
    window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/mga/sps/oauth/oauth20/",
  logoutISAM: window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/pkmslogout",
  refreshTokenISAM:
    window._env_.REACT_APP_ENDPOINT_OAUTH_ISAM + "/mga/sps/oauth/oauth20/token",
  getTokenC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID +
    "/myidentity/notifier/signinorsignup",
  logoutC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID + "/myidentity/notifier/singout",
  refreshTokenC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_C_ID +
    "/myidentity/notifier/RefreshToken",
  userDataC_ID:
    window._env_.REACT_APP_ENDPOINT_OAUTH_API_C_ID + "/api/Accounts/user",
};

const empleadoServices = {
  // IPE SERVICE:
  obtenerHistoricoEpisByIdEmpleado:
    window._env_.REACT_APP_API_URI + "/ipe/history",
  obtenerEpisPendientesByIdEmpleado:
    window._env_.REACT_APP_API_URI + "/ipe/pending",
  // TASK SERVICE:
  obtenerTareasPendientesByIdEmpleado:
    window._env_.REACT_APP_API_URI + "/task/pending",
  // IPE-BATCH SERVICE:
  actualizarEstadoNotificacionById:
    window._env_.REACT_APP_API_URI + "/ipe-batch/{id}",
};

module.exports = {
  auth,
  empleadoServices,
};
