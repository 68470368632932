import ApiService from "../Api/ApiService";
import { instanceEmpleados } from "../Api/Instance";
import { utils } from "../utils/helpers";
import apiUrl from "../Api/ApiUrl";

class HistoricoEpisService {
  constructor() {
    this.apiService = new ApiService(instanceEmpleados);
  }

  /**
   * Obtiene el historico de Epis de un empleado.
   * @param {*} idEmpleado
   */
  async obtenerHistoricoEpisByIdEmpleado() {
    const url = apiUrl.empleadoServices.obtenerHistoricoEpisByIdEmpleado;
    let response = await this.apiService.invokeGET(url);
    response = utils.generateResponseObject(response);
    return response;
  }
}
export default new HistoricoEpisService();
