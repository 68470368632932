/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from "react";
import { DatePicker } from "antd";
import { FORMATO_FECHA_DDMMYYYY } from "../../../../utils/constants";
import _ from "lodash";


class DateRangeFilter extends React.PureComponent {

  handleChange = (value) => {
    if (_.isEmpty(value)) {
      this.props.setSelectedKeys(null);
    } else {
      this.props.setSelectedKeys([value]);
    }
    this.props.confirm();
  };

  render() {
    return (
      < DatePicker.RangePicker
        id="filtro-rango-fechas"
        format={FORMATO_FECHA_DDMMYYYY}
        placeholder={["Desde", "Hasta"]}
        onChange={this.handleChange}
      />
    );
  }
}

export default DateRangeFilter;
