/* eslint-disable no-console */
import axios from "axios";
import { CatchPerm } from "correos-arq-auth";
import { storeArq } from "correos-arq-utils";

export const instanceEmpleados = axios.create({
  baseURL: window._env_.REACT_APP_API_URI,
  responseType: "json",
  Accept: "application/json",
  Authorization: "Bearer " + storeArq.data.auth.token,
});

const handleResponseSuccess = function (response) {
  /* Any status code that lie within the range of 2xx cause this function to trigger
    (Do something with response data)*/
  return response;
};

const handleReponseError = function (error) {
  /* Any status codes that falls outside the range of 2xx cause this function to trigger
    (Do something with response error)*/
  if (error.response !== undefined && error.response.status) {
    CatchPerm.catchAuth(error.response.status);
  }
  return error.response;
};

const handleRequestConfig = function (config) {
  config.headers.common.Authorization = "Bearer " + storeArq.data.auth.token;
  return config;
};

const handleRequestError = function (error) {
  return Promise.reject(error);
};

// Add a request interceptor
instanceEmpleados.interceptors.request.use(
  handleRequestConfig,
  handleRequestError
);

// Add a response interceptor
instanceEmpleados.interceptors.response.use(
  handleResponseSuccess,
  handleReponseError
);


export default { instanceEmpleados };
