import { UPDATE_DATOS_EPIS_PENDIENTES_EMPLEADOS } from "../constants/StoreConstants";

const initialState = {
  episPendientesEmpleados: [],
};

export const entregasEpisReducer = (state = initialState, action = []) => {
  switch (action.type) {
    case UPDATE_DATOS_EPIS_PENDIENTES_EMPLEADOS:
      return {
        ...state,
        episPendientesEmpleados: action.payload,
      };
    default:
      return state;
  }
};
