const rolesWeb = [
  {
    rol: window._env_.ISAM_ALLOWED_ROLES,
    accion: [
      {
        path: "/", sub: null
      },
      {
        path: "/epis",
        sub: [
          {
            path: "/entregas",
            sub: null,
          },
          {
            path: "/historico",
            sub: null,
          },
        ],
      },
    ],
    label: "Usuario Portal del Empleado",
  }
];
export default rolesWeb;
