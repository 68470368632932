/*
/ Clase para el proceso de peticiones Rest Api
*/

export default class APIService {
  constructor(instance) {
    this.instance = instance;
  }

  // Cabecera
  getAPIHelper() {
    return {
      "Content-Type": "application/json",
      authorization: window.localStorage.getItem("token"),
    };
  }

  /* GET */
  invokeGET(url) {
    return this.instance
      .get(`${url}`)
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        // handle error
        return error;
      })
      .finally(function () {
        // This is intentional
      });
  }

  /* PUT */
  invokePUT(url, data) {
    return this.instance
      .put(url, data)
      .then(function (response) {
        // handle success
        return response;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response;
        } else if (error.request) {
          return error.request;
        } else {
          return error;
        }
      });
  }
}
