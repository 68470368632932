import { UPDATE_DATOS_EPIS_PENDIENTES_EMPLEADOS } from "../constants/StoreConstants";

export const EntregasEpisActions = {
  // Obtener datos epis pendientes de Empleados:
  updateDatosEpisPendientesEmpleados(data) {
    return {
      type: UPDATE_DATOS_EPIS_PENDIENTES_EMPLEADOS,
      payload: data,
    };
  },
};

export default EntregasEpisActions;
