import { Input, Button, Icon } from 'antd';
import React, { Component } from "react";
import _ from "lodash";

class InputFilter extends Component {

  getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
        <div style={{ padding: 8 }}>
          <Input
            id={`filtro-input-` + dataIndex}
            ref={(node) => {
              this.searchInput = node;
            }}
            placeholder={`Buscar ${title}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys([e.target.value])
            }
            onPressEnter={() =>
              confirm()
            }
            style={{ width: 190, marginBottom: 8, display: "block" }}
          />
          <Button
            id="filtro-boton-buscar"
            type="primary"
            size="small"
            onClick={() => confirm()}
            style={{ width: 90, marginRight: 8 }}
          >Buscar
          </Button>
          <Button
            id="filtro-boton-reiniciar"
            type="default"
            size="small"
            onClick={() => clearFilters()}
            style={{ width: 90, marginRight: 8 }}
          >Reiniciar
          </Button>
        </div>
      ),
    filterIcon: (filtered) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      var returnValue = null;
      if (!_.isEmpty(record[dataIndex])) {
        returnValue = record[dataIndex]
          .toString()
          .toLowerCase()
          .startsWith(value.toLowerCase());
      }
      return returnValue;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
}

export default new InputFilter();
