// Códigos de error:
export const RESPONSE_ERRORS = [
  {
    codigo: "NO_RESPONSE_ERROR",
    msg: "No se ha recibido respuesta del servidor. Inténtelo de nuevo más tarde.",
  },
  {
    codigo: "ERROR_GENERICO",
    msg: "Ha ocurrido un error.",
  },
  // HTTP STATUS 404:
  {
    codigo: "ENTITY_NOT_FOUND",
    msg: "Se ha producido un error al acceder al recurso. No se ha encontrado.",
  },
  // HTTP STATUS 406:
  {
    codigo: "ENTITY_VALIDATON",
    msg: "",
  },
  // HTTP STATUS 409:
  {
    codigo: "ENTITY_EXISTS_CONFLICT",
    msg: "Se ha producido un error al realizar el alta: La entidad ya existe.",
  },
  // HTTP STATUS 423:
  {
    codigo: "OPTIMISTIC_LOCK_ERROR",
    msg: "Se ha producido un error en la integridad de datos: Los datos que se intentan modificar no están actualizados.",
  },
  // HTTP STATUS 500:
  {
    codigo: "INTERNAL_SERVER_ERROR",
    msg: "Error interno.",
  },
  // HTTP STATUS 503:
  {
    codigo: "SIGHNO_CONNECTION_ERROR",
    msg: "Se ha producido un error al conectar con SIGHNO. Inténtelo de nuevo más tarde.",
  },
  {
    codigo: "SIGHNO_DECODE_ERROR",
    msg: "Se ha producido un error al procesar los datos de SIGHNO.",
  },
];

// Estados de lotes:
export const ESTADO_LOTE = {
  NO_LEIDO: {
    codigo: "01",
    descripcion: "Nueva",
  },
  PENDIENTE: {
    codigo: "02",
    descripcion: "Pendiente",
  },
  ACEPTADO: {
    codigo: "03",
    descripcion: "Aceptado",
  },
  RECHAZADO: {
    codigo: "04",
    descripcion: "Rechazado",
  },
};

// TABLAS //
export const TABLA_RESULTADOS_HISTORICO = "TABLA_RESULTADOS_HISTORICO";
export const TABLA_TAREAS_PENDIENTES = "TABLA_TAREAS_PENDIENTES";

// OTRAS CONSTANTES:
export const MOBILE_AVERAGE_WIDTH = 480;
export const FORMATO_FECHA_DDMMYYYY = "DD/MM/YYYY";
