import _ from "lodash";
import { utils } from "../../utils/helpers";

class InicioMapper {
  jsonToTareasPendientesObject(data) {
    let registro = null;
    if (!utils.isEmptyObject(data)) {
      registro = {
        id: data.id,
        fecha: data.creationDate,
        descripcion: data.subject,
        estado: data.status,
        fechaCaducidad: data.expirationDate,
      };
    }

    return registro;
  }

  jsonToTareasPendientesObjectList(dataList) {
    let objectList = [];
    if (!_.isEmpty(dataList) && dataList.length > 0) {
      objectList = dataList.map(registro =>
        this.jsonToTareasPendientesObject(registro)
      );
    }
    return objectList;
  }
}
export default new InicioMapper();
