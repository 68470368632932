const roleswebCorreosID = {
  accion: [
    { path: "/", sub: null },
    {
      path: "/epis",
      sub: [
        {
          path: "/entregas",
          sub: null,
        },
        {
          path: "/historico",
          sub: null,
        },
      ],
    },
  ],
  label: "Usuario general",
};

export default roleswebCorreosID;
