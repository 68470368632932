import { REINICIAR_ESTADO_STORE, DISPLAY_LOADING } from "../constants/StoreConstants";
import appReducer from ".";

const initialState = {
  isLoading: false,
  error: null,
};

export const commonReducer = (state = initialState, action = []) => {
  switch (action.type) {
    case DISPLAY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case REINICIAR_ESTADO_STORE:
      return appReducer(undefined, REINICIAR_ESTADO_STORE);
    default:
      return state;
  }
};
