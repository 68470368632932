import { Col, Row } from "antd";
import React from "react";
require("./loading.scss");

class LoadingPage extends React.Component {
  render() {
    return (
      <div className="loading">
        <div className="Home">
          <Row>
            <Col span={1} />
            <Col lg={12}>
              <img alt="" src={""} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default LoadingPage;
