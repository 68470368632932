import { UPDATE_DATOS_HISTORICO_EMPLEADOS } from "../constants/StoreConstants";

export const HistoricoEpisActions = {
  // Obtener datos del historico de Empleados:
  updateDatosHistoricoEmpleados(data) {
    return {
      type: UPDATE_DATOS_HISTORICO_EMPLEADOS,
      payload: data,
    };
  },
};

export default HistoricoEpisActions;
