import { REINICIAR_ESTADO_STORE, DISPLAY_LOADING } from "../constants/StoreConstants";

const CommonActions = {
  reiniciarStateAll() {
    return {
      type: REINICIAR_ESTADO_STORE,
    };
  },

  displayLoading(showLoading) {
    return {
      type: DISPLAY_LOADING,
      payload: showLoading,
    };
  },
};

export default CommonActions;
