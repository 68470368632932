import { UPDATE_TAREAS_PENDIENTES } from "../constants/StoreConstants";

export const inicioActions = {
  // Obtener tareas pendientes del empleado:
  updateTareasPendientesEmpleado(data) {
    return {
      type: UPDATE_TAREAS_PENDIENTES,
      payload: data,
    };
  },
};

export default inicioActions;
