import { UPDATE_DATOS_HISTORICO_EMPLEADOS } from "../constants/StoreConstants";

const initialState = {
  historicoEpisEmpleados: [],
};

export const historicoEpisReducer = (state = initialState, action = []) => {
  switch (action.type) {
    // Obtener Datos del Empleado:
    case UPDATE_DATOS_HISTORICO_EMPLEADOS:
      return {
        ...state,
        historicoEpisEmpleados: action.payload,
      };
    default:
      return state;
  }
};
