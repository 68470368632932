import { Table, Button } from 'antd';
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getColumns } from "./columnas/HistoricoTableColumns";
import _ from "lodash";

class TablaHistorico extends Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
  };

  // Cambio en los valores del filtro (ya sea por añadirlos o eliminarlos):
  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  // Limpia los filtros y ordenación de los resultados:
  clearAll = () => {
    this.setState({
      filteredInfo: null,
      sortedInfo: null,
    });
  };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return (
      <>
        <Button
          id="filtro-boton-reiniciar"
          icon="undo"
          type="default"
          size="small"
          onClick={this.clearAll}
          disabled={_.isEmpty(sortedInfo) && _.isEmpty(filteredInfo)}
          style={{ width: 100, marginBottom: 15, marginRight: 15 }}
        >Reiniciar
        </Button>
        <Table
          id="tabla-resultados"
          dataSource={this.props.dataSource || []}
          loading={this.props.isLoading}
          style={this.props.style}
          scroll={this.props.scroll}
          columns={getColumns(this.props.t, this.props.dataSource, filteredInfo, sortedInfo)}
          bordered
          pagination={false}
          rowKey={this.props.rowKey}
          onChange={this.handleChange} />
      </>
    );
  }
}

export default withTranslation()(TablaHistorico);