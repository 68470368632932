import React from "react";
import _ from "lodash";
import { Tag } from "antd";
import { MOBILE_AVERAGE_WIDTH } from "./constants";
import moment from "moment";
import { ESTADO_LOTE, FORMATO_FECHA_DDMMYYYY } from "../utils/constants";
import { RESPONSE_ERRORS } from "../utils/constants";
import { logout } from "../utils/logout";

// Formatea una fecha String a formato de visualización de pantalla:
function stringToDate(date) {
  var formattedTime = "";
  if (date) {
    formattedTime = moment(new Date(date), FORMATO_FECHA_DDMMYYYY)
      .locale("es")
      .format("DD/MM/YYYY");
  }
  return formattedTime;
}

//Mirar si el dispositivo es un movil
function isMobile() {
  return this.getWindowDimensions().width <= MOBILE_AVERAGE_WIDTH;
}

//obtenemos el tamaño de la pantalla
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function generateResponseObject(response) {
  let isSuccess = false;
  let errorMessage = null;
  if (_.isEmpty(response)) {
    errorMessage = RESPONSE_ERRORS.find((e) => e.codigo === "NO_RESPONSE_ERROR");
  } else {
    isSuccess = response.status >= 200 && response.status <= 299;
    if (!isSuccess) {
      if (response.status === 406) {
        errorMessage = RESPONSE_ERRORS.find((e) => e.codigo === response.data.errorCode);
        errorMessage.msg = errorMessage.msg + response.data.validaciones.map((val) => val.descripcion);
      } else if (response.data.code === "403") {
        logout.handleUrlLogin();
      } else if (response.data.apierror) {
        errorMessage = RESPONSE_ERRORS.find((e) => e.codigo === response.data.apierror.errorCode);
      } else {
        errorMessage = RESPONSE_ERRORS.find((e) => e.codigo === "ERROR_GENERICO");
      }
    }
  }
  return { ...response, isSuccess, errorMessage };
}

/*
 * Función que comprueba si un objeto está vacío (es decir, si todas sus propiedades lo están).
 */
function isEmptyObject(object) {
  for (var x in object) {
    if (!_.isEmpty(object[x])) {
      return false;
    }
  }
  return true;
}

const styles = {
  tagSize: {
    width: "78px",
    textAlign: "center",
  },
  color: "blue",
};

function obtenerTagEstado(estado) {
  var color = styles.color;
  var tagSize = styles.tagSize;
  if (estado.code === ESTADO_LOTE.PENDIENTE.codigo) {
    color = "gold";
  } else if (estado.code === ESTADO_LOTE.ACEPTADO.codigo) {
    color = "green";
  } else if (estado.code === ESTADO_LOTE.RECHAZADO.codigo) {
    color = "red";
  }
  return (
    <Tag style={tagSize} color={color}>
      {estado.description}
    </Tag>
  );
}

function objectToURLParams(formdata) {
  return Object.keys(formdata)
    .reduce((result, key) => {
      var value = formdata[key];
      if (Array.isArray(value) && value.length > 0) {
        result.push(value.map((element) => `${key}=${element}`).join("&"));
      } else {
        if (value !== null && value !== undefined && value.length !== 0) {
          result.push(`${key}=${value}`);
        }
      }
      return result;
    }, [])
    .join("&");
}

export const utils = {
  isEmptyObject,
  stringToDate,
  isMobile,
  generateResponseObject,
  getWindowDimensions,
  obtenerTagEstado,
  objectToURLParams,
};
