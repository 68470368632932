import ApiService from "../Api/ApiService";
import apiUrl from "../Api/ApiUrl";
import { instanceEmpleados } from "../Api/Instance";
import { utils } from "../utils/helpers";

class EntregasService {
  constructor() {
    this.apiService = new ApiService(instanceEmpleados);
  }

  /**
   * Actualiza el estado de la notificación a partir de su identificador.
   * @param {*} idEmpleado
   */
  async actualizarEstadoNotificacionById(idNotificacion, nuevoEstado) {
    let url = apiUrl.empleadoServices.actualizarEstadoNotificacionById;
    url = url.replace("{id}", idNotificacion);
    let response = await this.apiService.invokePUT(url, nuevoEstado);
    response = utils.generateResponseObject(response);
    return response;
  }

  /**
   * Devuelve los lotes Epi pendientes del Empleado a partir de su identificador.
   * @param {*} idEmpleado
   */
  async obtenerEpisPendientesByIdEmpleado() {
    const url = apiUrl.empleadoServices.obtenerEpisPendientesByIdEmpleado;
    let response = await this.apiService.invokeGET(url);
    response = utils.generateResponseObject(response);
    return response;
  }
}

export default new EntregasService();
