import * as React from "react";

function monitor(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 154.11 121.92"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-4{fill:#fff;stroke:#002e6d;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.43px}"
          }
        </style>
      </defs>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__Capa_1-2" data-name="Capa 1">
          <path
            d="M143.17 98s8-.81 8-2.71l1.74-13.08H1.21L2.4 94.6S1.21 98 10.94 98z"
            fill="#fff"
          />
          <path
            d="M11 1.22s-9.79 0-9.79 9.78v71.21H152.9V11s0-9.73-9.73-9.73z"
            fill="#ffcd00"
          />
          <rect
            x={1.22}
            y={1.22}
            width={151.68}
            height={96.39}
            rx={6.86}
            stroke="#002e6d"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.43}
            fill="none"
          />
          <path
            className="prefix__cls-4"
            d="M2.4 82.46h150.45M79.69 97.61h21.43a42.67 42.67 0 00.57 10.85c.83 4.12 3.86 7.78 8.34 10.08 1.26.64.67 2.16-.83 2.16h-59c-1.5 0-2.08-1.52-.83-2.16 4.49-2.3 7.52-6 8.34-10.08a42.94 42.94 0 00.58-10.85zM80.89 90.07a1.2 1.2 0 11-1.2-1.2 1.19 1.19 0 011.2 1.2z"
          />
        </g>
      </g>
    </svg>
  );
}

export default monitor;
