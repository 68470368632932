import * as React from "react";

function people(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 227.13 198.12"
      {...props}
    >
      <defs>
        <style>
          {
            ".prefix__cls-1,.prefix__cls-2{fill:none;stroke:#002e6d;stroke-linecap:round;stroke-width:2.99px}.prefix__cls-1{stroke-linejoin:round}.prefix__cls-2{stroke-miterlimit:10}"
          }
        </style>
      </defs>
      <g id="prefix__Capa_2" data-name="Capa 2">
        <g id="prefix__Capa_1-2" data-name="Capa 1">
          <path
            className="prefix__cls-1"
            d="M182.46 152.07l6.87 34.14L179 196.62m-3.44-44.55l-6.88 34.14L179 196.62m6.94-48l-6.94 6.8-7-6.8"
          />
          <path
            className="prefix__cls-2"
            d="M195.28 103.47l5.49-2.51m-43.63 0l5.61 2.51m12.17.36h8.2m10.88 4.92h-9.69a1.23 1.23 0 01-1.23-1.24v-5.31a1.23 1.23 0 011.23-1.24H194a1.23 1.23 0 011.24 1.24v5.31a1.23 1.23 0 01-1.24 1.24zm-20.36 0H164a1.24 1.24 0 01-1.24-1.24v-5.31A1.24 1.24 0 01164 101h9.69a1.24 1.24 0 011.24 1.24v5.31a1.24 1.24 0 01-1.25 1.2zm-14.85-17.91a8.17 8.17 0 014.57-4c3.11-1.13 6.61-.23 9.63 1.15 4.36 2 8.17 4.34 13.06 4.87a22.36 22.36 0 0013.11-2.13m1.6 11S202 76.2 179 76.2s-21.86 25.65-21.86 25.65m0 0c-2.39.68-4.13 3.53-4.13 7.05 0 3.86 2.11 6.93 4.86 7.18a26.56 26.56 0 009.13 14.25l-.7 6.12a3.36 3.36 0 01-1.47 2.4 3.12 3.12 0 01-.94.44l-15.4 4.47a14.59 14.59 0 00-6 3.16 17.79 17.79 0 00-6.12 10.23l-3.61 18.09 24.47 5.07m40.89.6l27.34-5.67-3.61-18.09a17.86 17.86 0 00-6.12-10.23 14.61 14.61 0 00-6-3.14l-15.46-4.49a3.36 3.36 0 01-2.4-2.84l-.71-6.12a26.41 26.41 0 009.06-14.25c2.76-.24 4.88-3.31 4.88-7.18 0-3.58-1.8-6.48-4.26-7.09"
          />
          <path
            className="prefix__cls-1"
            d="M199.16 165.36l14.26-2.69M191.62 135L179 146.3 166.41 135"
          />
          <path
            d="M69.79 31.34S71.07 5.73 48 5.73 26.17 31.37 26.17 31.37m0 0c-2.39.69-4.12 3.54-4.12 7.05 0 3.87 2.11 6.94 4.86 7.19A26.5 26.5 0 0036 59.86L35.27 66a3.34 3.34 0 01-1.46 2.4 3.52 3.52 0 01-.94.44l-15.4 4.47a14.69 14.69 0 00-6 3.16 17.83 17.83 0 00-6.16 10.21l-3.62 18.09 48.86 11.65m34.12-40a14.68 14.68 0 00-6-3.14l-15.45-4.46a3.66 3.66 0 01-.94-.44A3.39 3.39 0 0160.81 66l-.71-6.12a26.37 26.37 0 009.07-14.25c2.76-.24 4.88-3.31 4.88-7.19 0-3.57-1.8-6.47-4.26-7.08"
            strokeWidth={2.9}
            strokeMiterlimit={10}
            fill="none"
            stroke="#002e6d"
            strokeLinecap="round"
          />
          <path
            strokeWidth={2.9}
            strokeLinejoin="round"
            fill="none"
            stroke="#002e6d"
            strokeLinecap="round"
            d="M60.65 64.54L48.04 75.83l-12.6-11.29"
          />
          <path
            className="prefix__cls-1"
            d="M109.77 30.5v13.27a20 20 0 0040.05 0V30.5zm48.2 0c-.15-.95-.3-1.9-.44-2.85-2.91-16.17-15.35-26.15-27.74-26.15s-24.83 10-27.74 26.15c-.15 1-.29 1.9-.43 2.85m40.56 41.28a15.51 15.51 0 00.2-2.39v-10.2a20.14 20.14 0 01-25.18 0v10.2a15.51 15.51 0 00.2 2.39 73.29 73.29 0 00-16.16 3.84M103 75l1.4-.45.51-.16 1.6-.45.34-.1c.6-.16 1.2-.31 1.82-.45l.16-.05a85.73 85.73 0 018.52-1.55 16.74 16.74 0 01-.19-2.38V59.19a20.14 20.14 0 0025.18 0v10.2a15.38 15.38 0 01-.2 2.38 86.31 86.31 0 018.55 1.56h.1c.64.16 1.27.31 1.88.48l.25.07c.57.15 1.14.32 1.69.48l.34.11a48.36 48.36 0 0110.31 4.39l-7.77-51.23c-2.87-16.15-15.31-26.13-27.7-26.13s-24.83 10-27.74 26.15l-7.77 51.23a45 45 0 018.21-3.7z"
          />
          <path
            className="prefix__cls-2"
            d="M85.88 78.4c-9.45 0-19.36 5.26-23.32 14.11a42 42 0 00-2.94 12.4c-.7 6-1 11.66-3.87 17a12.88 12.88 0 01-10.35 7.31 19.1 19.1 0 0019.65 8.3M85.88 78.4c9.46 0 19.37 5.26 23.33 14.11a42 42 0 012.94 12.4c.7 6 1 11.66 3.87 17a12.88 12.88 0 0010.35 7.31 19.1 19.1 0 01-19.65 8.3"
          />
          <path
            className="prefix__cls-2"
            d="M105.8 191.42l28.65-5.94-3.79-19a18.73 18.73 0 00-6.4-10.71 15.38 15.38 0 00-6.28-3.3l-16.19-4.7a3.45 3.45 0 01-1-.46 3.52 3.52 0 01-1.54-2.52l-.74-6.41c4.92-4.48 6.6-8.47 6.87-14.16.07-8.34-3.44-24-3.44-24C98.08 87 85.89 87.83 85.89 87.83s-12.2-.8-16.08 12.4c0 0-3.5 15.69-3.43 24 .27 5.69 2 9.68 6.87 14.16l-.75 6.41a3.48 3.48 0 01-1.5 2.55 3.65 3.65 0 01-1 .46l-16.2 4.7a15.38 15.38 0 00-6.28 3.3 18.68 18.68 0 00-6.4 10.71l-3.79 19 28.67 5.9"
          />
          <path
            className="prefix__cls-1"
            d="M99.09 143.33l-13.2 11.83-13.21-11.83"
          />
          <path
            className="prefix__cls-2"
            d="M85.88 177.15v15.14M68 154.4l9.32 8.95 8.59-8.19m17.92-.76l-9.32 8.95-8.6-8.19"
          />
        </g>
      </g>
    </svg>
  );
}

export default people;
