import {
  EntregasContainer,
  HistoricoContainer,
  InicioContainer,
} from "../pages";

const pathRoutes = [
  {
    path: "/",
    component: InicioContainer,
    label: "router_routes.home",
    public: false,
  },
  {
    path: "/epis",
    component: EntregasContainer,
    label: "router_routes.epis",
    public: false,
  },
  {
    path: "/epis/historico",
    component: HistoricoContainer,
    label: "router_routes.historico",
    public: false,
  },
  {
    path: "/epis/entregas",
    component: EntregasContainer,
    label: "router_routes.entregas",
    public: false,
  },
  {
    path: "/epis/entregas/:id",
    component: EntregasContainer,
    label: "router_routes.entregas",
    public: false,
  },
];

export default pathRoutes;
